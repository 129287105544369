<template>
    <div class="main-conainer">
      <router-view/>
    </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
  },
  watch() {
  },
  methods: {


  },
  mounted() {

  },
};
</script>

<style>
.fixed img {
  width: 36px;
  height: 36px;
}

.main-conainer {
  flex: 1;
}

</style>
