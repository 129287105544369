import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from "vant"
import "vant/lib/index.css"
import request from "@/config/request";

import Api from "@/config/api";




const app = createApp(App);


app.config.globalProperties.$request = request;
app.config.globalProperties.$api = Api;
app.use(store).use(router).use(vant).mount('#app')
