<template>
  <div class="home home-container" style="padding: 3vh 0;">
    <div style="display: flex;flex-direction:column;align-items:center;justify-content: center">
      <img src="@/assets/image/tether.svg" alt="dark" width="70px" height="70px" style="margin-bottom: 15px;">
      <div class="deposit-tip">
        请扫描二维码或点击金额和地址粘贴转账<br>
        USDT(trc-20)支付。<br>
        <b>转账金额必须为下方显示的金额且需要在倒计时内完成转账，否则无法被系统确认！</b>
      </div>

      <div v-if="orderData">
        <div class="section" v-if="orderData.status=='1'">
          <div class="title">
            <h1 class="amount parse-amount" data-clipboard-text="0.14" id="usdt" >
              <span style="font-weight: bold">{{orderData.transUsdtNum}} </span><span style=" font-size: 13px;">USDT.TRC20</span>
            </h1>
          </div>
          <div class="address parse-action"  v-clipboard="orderData.toAddress"  v-clipboard:success="clipboardSuccessHandler"
               v-clipboard:error="clipboardErrorHandler">
            <span style="margin-right: 10px"> {{orderData.toAddress}}</span> <i class="el-icon-document-copy"></i>

          </div>
          <div class="main">
            <div class="qr-image" id="qrcode">
              <vue-qr  :text="orderData.toAddress" :size="230"></vue-qr>
            </div>
          </div>
          <CountDown :time="20 * 60 * 1000" format="mm 分 ss 秒" @finish="onCountdownEnd()"/>
        </div>
        <div v-else-if="orderData.status=='2'" style="margin-top:50px;width: 100%">
          <div style="display: flex;flex-direction: column;align-items: center">
            <img src="@/assets/image/pay-success.png" alt="dark" width="100px" height="100px" style="margin-bottom: 15px;">
            <div>订单支付成功 ！</div>
          </div>
<!--          <div style="margin-top:20px;display:flex;flex-direction:row;padding:20px;font-size: 13px;">-->
<!--            <div style="display:flex;flex-direction:column;color:#7b7b7b">-->
<!--              <div style=" margin-bottom:15px;">-->
<!--                <div style="width: 150px;">交易哈希：</div><div style="flex: 1">{{orderData.txId}}</div>-->
<!--              </div>-->
<!--              <div style=" margin-bottom:15px;">-->
<!--                <div style="width: 150px;">支付USDT：</div><div style="flex: 1">{{orderData.transUsdtNum}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div v-else-if="orderData.status=='3'" style="margin-top:50px;width: 100%">
          <div style="display: flex;flex-direction: column;align-items: center">
            <img src="@/assets/image/close-order.png" alt="dark" width="100px" height="100px" style="margin-bottom: 15px;">
            <div>订单超时已关闭 ！</div>
          </div>
        </div>
      </div>
      <div v-else>
        订单查询错误
      </div>
    </div>
  </div>
</template>
<script>
import { CountDown } from 'vant';
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
  name: "HomeView",
  components: {
    CountDown,
    vueQr
  },
  data() {
    return {
      orderData: {},
      intervalId: null,
      orderState:"2"
    };
  },
  computed: {

  },
  beforeDestroy() {
    // 组件销毁时，清除定时器
    this.stopPolling();
  },
  methods: {
    onCountdownEnd() {
      console.log('countdown end~')
    },
    getInfo() {
      this.$request.get("mokapay/getOrder/"+this.$route.query.trade_no).then((res) => {
        console.log("res is ",res)
        if(res.data.code=='1'){
          this.orderData = res.data.data
        }else{
          this.orderData = null
        }

      });
    },
    //定义复制失败的回调
    clipboardSuccessHandler({ value, event }) {
      console.log('success', value)
      this.$message.success('已复制')
    },
    //定义复制成功的回调方法
    clipboardErrorHandler({ value, event }) {
      console.log('error', value)
    },
    startPollingOrderStatus(){
      this.intervalId = setInterval(() => {
        this.checkOrderStatus();
      }, 5000); // 每5秒查询一次
    },
    // 停止轮询
    stopPolling() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
//     PAY_ING("1","未支付"),
//     PAY_FINISHED("2","已支付"),
//     PAY_CANCEL("3","订单关闭"),
    async checkOrderStatus() {
      this.$request.get("mokapay/getOrder/"+this.$route.query.trade_no).then((res) => {
        if(res.data.code=='1'){
          const data = res.data.data;
          if (data.status === '2' || data.status === '3' ) {
            this.orderData = res.data.data
            this.stopPolling(); // 停止轮询
          }
        }else{
          this.orderData = null
        }
      });
    }
  },
  mounted() {
    this.getInfo()
    console.log("this.$route.query.noGoBack",this.$route.query.noGoBack)
    this.startPollingOrderStatus()
  },
};
</script>

<style lang="scss" scoped>
.deposit-way {
  border: 1px solid;
  padding: 12px;
  border-radius: 6px;
  margin-right: 12px;
  width: 189px;
  color: rgba(0, 0, 0, .65);
}

.order-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.deposit-tip{
  width: 320px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 16px;text-align: center;
  color: #777;
  line-height: 1.6em;
  margin-bottom: 20px;
}
.section{
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 20px;
  background-color: #FFFFFF;
  .address {
    font-size: 12px;
    color: #444;
    margin-bottom: 30px;
    border-radius: 5px;
    white-space: normal;
    word-break: break-all;
    line-height: 1.5em;
  }

}
.timer{
  ul.downcount{
    list-style: none;
    margin: 30px 0 15px;
    padding: 0;
    display: block;
    li{
      display: inline-block;
      span {
        font-size: 24px;
        font-weight: 300;
        line-height: 24px;
        color: #444;
      }
      p {
        color: #a7abb1;
        font-size: 14px;
      }
    }
  }
}

</style>
