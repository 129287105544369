import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Layout from '@/layout/index'

const routes = [{
    path: '/',
    redirect: '/pay',
  },
  {
    path: '/pay',
    component: Layout,
    children: [{
      path: '/pay',
      component: HomeView
    }]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
