import Axios from "axios";
import api from "./api";
import {getCookie} from "@/util/data";

const instance = Axios.create({
    baseURL: api.api,
    timeout: 100000
});

instance.interceptors.request.use(config => {
    config.headers["X-Access-Token"] = sessionStorage.getItem("token") ? sessionStorage.getItem("token"): ''
    let lang = sessionStorage.getItem("lang") ?? "en"
    if(lang === 'en'){
        lang = 'en_US'
    }
    if(lang === 'cn'){
        lang = 'zh_CN'
    }
    if(lang === 'jp'){
        lang = 'ja_JP'
    }
    if(lang === 'ru'){
        lang = 'sv_SE'
    }
    if(lang === 'kor'){
        lang = 'ko_KR'
    }
    if(lang === 'sp'){
        lang = 'es_ES'
    }
    if(lang === 'th'){
        lang = 'th_TH'
    }
    config.headers["language"] = lang
    return config;
},error => {
    return Promise.reject(error);
})

export default instance;
